import React, { useState, useEffect, useRef, useMemo } from "react";
import { Stage, Layer, Image as KonvaImage, Line, Group } from "react-konva";
//touch test pending
export default function Home({ jsonData }) {

    const [tiles, setTiles] = useState(new Map());
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState(null);
    const [sidePanelOpen, setSidePanelOpen] = useState(false);
    const [panelContentUrl, setPanelContentUrl] = useState("");
    const [imageId, setImageId] = useState(null);
    const [imageMetadata, setImageMetadata] = useState(null);
    const [zoom, setZoom] = useState(0);
    const stageRef = useRef(null);
    const containerRef = useRef(null);
    const [stationApi, setStationApi] = useState(new Map());

    const TILE_SIZE = 256;
    const MIN_SCALE = 0.1;
    const MAX_SCALE = 10;
    const annotations = jsonData.annotations.filter(anno => anno.image_id === 1);

    // Initialize image with backend
    useEffect(() => {
        const initializeImage = async () => {
            try {
                const response = await fetch('/api/images/initialize', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        imagePath: jsonData.images.find(img => img.id === 1).file_name
                    })
                });

                const data = await response.json();
                setImageId(data.imageId);
                setImageMetadata(data.metadata);

                // Calculate initial transform
                const { width, height } = data.metadata;
                const transform = calculateInitialTransform(width, height);
                setScale(transform.scale);
                setPosition(transform.position);
            } catch (error) {
                console.error('Error initializing image:', error);
            }
        };

        initializeImage();
    }, [jsonData]);

    const calculateInitialTransform = (width, height) => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const scaleX = (viewportWidth * 0.8) / width;
        const scaleY = (viewportHeight * 0.8) / height;
        const newScale = Math.min(scaleX, scaleY);

        return {
            scale: newScale,
            position: {
                x: (viewportWidth - (width * newScale)) / 2,
                y: (viewportHeight - (height * newScale)) / 2
            }
        };
    };

    // Load visible tiles based on viewport
    const loadVisibleTiles = async () => {
        if (!imageId || !imageMetadata) return;

        const stage = stageRef.current;
        if (!stage) return;

        const viewport = {
            x: -position.x / scale,
            y: -position.y / scale,
            width: stage.width() / scale,
            height: stage.height() / scale
        };

        const startTileX = Math.floor(viewport.x / TILE_SIZE);
        const startTileY = Math.floor(viewport.y / TILE_SIZE);
        const endTileX = Math.ceil((viewport.x + viewport.width) / TILE_SIZE);
        const endTileY = Math.ceil((viewport.y + viewport.height) / TILE_SIZE);

        const newTiles = new Map();
        const loadTilePromises = [];

        for (let y = startTileY; y <= endTileY; y++) {
            for (let x = startTileX; x <= endTileX; x++) {
                const tileKey = `${x}-${y}-${zoom}`;

                if (!tiles.has(tileKey) &&
                    x >= 0 && y >= 0 &&
                    x * TILE_SIZE < imageMetadata.width &&
                    y * TILE_SIZE < imageMetadata.height) {
                    loadTilePromises.push(
                        fetch(`/api/images/tile/${imageId}/${x}/${y}/${zoom}`)
                            .then(response => response.blob())
                            .then(blob => {
                                return new Promise(resolve => {
                                    const img = new Image();
                                    img.src = URL.createObjectURL(blob);
                                    img.onload = () => {
                                        newTiles.set(tileKey, {
                                            image: img,
                                            x: x * TILE_SIZE,
                                            y: y * TILE_SIZE
                                        });
                                        resolve();
                                    };
                                });
                            })
                    );
                }
            }
        }

        await Promise.all(loadTilePromises);
        setTiles(new Map([...tiles, ...newTiles]));
    };

    useEffect(() => {
        loadVisibleTiles();
    }, [position, scale, zoom, imageId]);

    useEffect(() => {
        fetch('https://cardbuilderapi.uat.experien.city/import/station-opacity?station_ids=23645,23646,23647,23648,23649,23650,23651,23652,23653,23654,23655,23656,23657,23658,23659,23660,23661,23662,23663,23664,23665,23666,23667,23668,23669,23670,23671,23672,23673,23674,23675,23676,23677,23678,23679,23680,23681,23682,23683,23684,23685,23686,23687,21908,21909,21910,21911,21912,21913,21914,21915,21916,21917,21918,21919,21920,21921,21922,21923,21924,21925,21926,21927,21928,21929,21930,21931,21932,21933,21934,21935,21936,21937,21938,21939,21940,21941,21942,7468,7469,7470,7471,7472,7474,7475,7476,7477,7478,7521,7569,9547,9578,9743,9745,10154,10247,10276,11537,11739,11789,11807,11883,12236,12743,13259,13882,15163,15169,22585,30591,34233,34234,27245,32742,32743,32744,32745,33134,33135,33137,33139,33140,33141,33142,33143,33144,33145,33146,33147,33148,33149,33150,33151,33152,33153,20693,20694,20695,20696,20697,20698,20699,20700,20701,20702,20703,20704,20705,20706,20707,20708,20709,20710,20711,20712,20713,20714,20715,20716,20717,20718,20719,20720,20721,20722,20723,20724,20725,20726,20727,20728,20729,20730,20731,20732,20733,20734,20735,20736,20737,20738,20739,20740,20741,20742,20743,20744,20745,20746,20747,20748,20749,20750,20751,20752,20753,20754,20755,20756,20757,20758,20759,20760,20761,20762,20763,20764,20765,20766,20767,20768,20769,20770,20771,20772,20871,20872,20873,20874,20875,20876,20877,20878,20879,20880,20881,20882,20883,20884,20885,20886,20887,20888,20889,20890,20891,21268,21269,21270,21271,21272,21273,21274,21275,21276,21277,21278,21279,21280,21281,21282,21283,21284,21285,21286,21287,21288,21289,21290,21291,21292,21293,21294,21295,21296,21297,21298,21299,21300,21301,21302,21303,21304,21305,21306,21307,21308,21309,21310')
            .then(res => res.json())
            .then(data => {
                const opacityMap = new Map();
                data.data.stations.forEach(station => {
                    opacityMap.set(station.idStations, parseFloat(station.tessera_opacity) || 0);  // Default to 1 if no opacity
                });
                console.log(opacityMap);
                setStationApi(opacityMap); // Set the opacity map with station id and opacity values

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Improved touch handling
    const [touchState, setTouchState] = useState({
        lastCenter: null,
        lastDistance: 0,
        isDragging: false,
        startPosition: null,
        lastScale: 1,
        startScale: 1
    });

    const getDistance = (touch1, touch2) => {
        return Math.sqrt(
            Math.pow(touch1.clientX - touch2.clientX, 2) +
            Math.pow(touch1.clientY - touch2.clientY, 2)
        );
    };

    const getCenter = (touch1, touch2) => ({
        x: (touch1.clientX + touch2.clientX) / 2,
        y: (touch1.clientY + touch2.clientY) / 2
    });

    const handleTouchStart = (e) => {
        const touches = e.evt.touches;

        if (touches.length === 2) {
            e.evt.preventDefault();
            const distance = getDistance(touches[0], touches[1]);
            const center = getCenter(touches[0], touches[1]);

            setTouchState({
                lastCenter: center,
                lastDistance: distance,
                isDragging: false,
                startPosition: null,
                lastScale: scale,
                startScale: scale
            });
        } else if (touches.length === 1) {
            setTouchState({
                ...touchState,
                isDragging: true,
                startPosition: {
                    x: touches[0].clientX,
                    y: touches[0].clientY
                }
            });
        }
    };

    const handleTouchMove = (e) => {
        const touches = e.evt.touches;
        const stage = stageRef.current;

        if (touches.length === 2) {
            e.evt.preventDefault();

            const distance = getDistance(touches[0], touches[1]);
            const center = getCenter(touches[0], touches[1]);

            if (!touchState.lastDistance) return;

            const distanceRatio = distance / touchState.lastDistance;
            let newScale = touchState.lastScale * distanceRatio;

            // Constrain scale
            newScale = Math.max(MIN_SCALE, Math.min(MAX_SCALE, newScale));

            // Calculate new position to zoom towards touch center
            const pointer = {
                x: center.x - stage.container().offsetLeft,
                y: center.y - stage.container().offsetTop
            };

            const mousePointTo = {
                x: (pointer.x - position.x) / touchState.lastScale,
                y: (pointer.y - position.y) / touchState.lastScale
            };

            const newPos = {
                x: pointer.x - mousePointTo.x * newScale,
                y: pointer.y - mousePointTo.y * newScale
            };

            setScale(newScale);
            setPosition(newPos);

            // Update zoom level for tile loading
            const newZoom = Math.round(Math.log2(newScale));
            if (newZoom !== zoom) {
                setZoom(newZoom);
            }

            setTouchState({
                ...touchState,
                lastDistance: distance,
                lastCenter: center,
                lastScale: newScale
            });
        } else if (touches.length === 1 && touchState.isDragging) {
            const dx = touches[0].clientX - touchState.startPosition.x;
            const dy = touches[0].clientY - touchState.startPosition.y;

            setPosition({
                x: position.x + dx,
                y: position.y + dy
            });

            setTouchState({
                ...touchState,
                startPosition: {
                    x: touches[0].clientX,
                    y: touches[0].clientY
                }
            });
        }
    };

    const handleTouchEnd = () => {
        setTouchState({
            lastCenter: null,
            lastDistance: 0,
            isDragging: false,
            startPosition: null,
            lastScale: scale,
            startScale: scale
        });
    };

    const handleWheel = (e) => {
        e.evt.preventDefault();
        const stage = stageRef.current;
        const oldScale = scale;
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - position.x) / oldScale,
            y: (pointer.y - position.y) / oldScale
        };

        const newScale = e.evt.deltaY > 0 ?
            oldScale * 0.9 :
            oldScale * 1.1;

        setScale(newScale);

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale
        };

        setPosition(newPos);

        const newZoom = Math.round(Math.log2(newScale));
        if (newZoom !== zoom) {
            setZoom(newZoom);
        }
    };

    // Render tiles
    const renderTiles = useMemo(() => {
        return Array.from(tiles.entries()).map(([key, tile]) => (
            <KonvaImage
                key={key}
                image={tile.image}
                x={tile.x}
                y={tile.y}
                width={TILE_SIZE}
                height={TILE_SIZE}
            />
        ));
    }, [tiles]);

    if (!position || !imageMetadata) {
        return <div>Loading...</div>;
    }

    const categoryColors = {
        1: "#000000", // Black Stone
        2: "#FF0000", // Red Stone
        3: "#0000FF", // Blue Stone
        4: "#FFFFFF", // White Stone
        5: "#FFFF00", // Jesus
        6: "#FFA500", // Anchor
        7: "#008000", // Boat
        8: "#800080", // Menora
        9: "#808080", // Gray
    };


    const handlePolygonClick = (annotation) => {
        const url = `https://cardviewer.beta.experien.city/?parentURL=https%3A%2F%2Fbeta.experien.city&frameId=402&stationId=${annotation.station_id}`;
        console.log('URL being set:', url); // Add this log
        setSidePanelOpen(true);
        setPanelContentUrl(url);
    };


    function hexToRgb(hex) {
        // Remove '#' if present
        hex = hex.replace(/^#/, '');

        // Parse the color
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return [r, g, b];
    }


    return (
        <div ref={containerRef} style={{
            width: '100%',
            height: '100vh',
            touchAction: 'none',
            userSelect: 'none',
            overflow: 'hidden'
        }}>
            <Stage
                ref={stageRef}
                width={window.innerWidth}
                height={window.innerHeight}
                onWheel={handleWheel}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                scale={{ x: scale, y: scale }}
                position={position}
                draggable
            >
                <Layer>
                    <Group>
                        {renderTiles}
                    </Group>
                    {annotations.map((annotation, index) => {
                        const stationValue = stationApi.get(annotation.station_id);
                        const calculatedOpacity = stationValue > 0 ? stationValue / 100 : 0;
                        const fillColor = stationValue
                            ? `rgba(${hexToRgb(categoryColors[annotation.category_id]).join(',')}, ${calculatedOpacity})`
                            : 'transparent';
                        return (
                            <Line
                                key={index}
                                points={annotation.segmentation.flat()}
                                closed
                                fill={fillColor}
                                stroke={categoryColors[annotation.category_id]}
                                strokeOpacity={1}
                                strokeWidth={2 / scale}
                                onClick={() => handlePolygonClick(annotation)}
                                onMouseEnter={e => {
                                    e.target.strokeWidth(2 / scale);
                                    e.target.getLayer().batchDraw();
                                }}
                                onMouseLeave={e => {
                                    e.target.strokeWidth(2 / scale);
                                    e.target.getLayer().batchDraw();
                                }}
                            />
                        );
                    })}
                </Layer>
            </Stage>
            {sidePanelOpen && (
                <div className="side-panel fixed bottom-0 left-0 h-[80%] bg-white shadow-md z-[1000] md:w-[20%] w-[30%]">
                    <button onClick={() => setSidePanelOpen(false)} style={{ position: 'absolute', top: '0px', right: '0px', border: "none", color: "#fff", backgroundColor: "#823a5e", zIndex: "1050", fontWeight: "bold", paddingInline: "10px", paddingBlock: "5px" }}>
                        X
                    </button>
                    {/* Add this to debug */}
                    <div style={{ position: 'absolute', top: '20px', fontSize: '10px' }}>
                        Loading URL: {panelContentUrl}
                    </div>
                    <iframe
                        allowFullScreen={true}
                        allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; autoplay; camera; microphone; display-capture; xr-spatial-tracking"
                        src={panelContentUrl}
                        style={{ position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%" }}
                        title="side panel"
                        onError={(e) => console.log('iframe error:', e)} // Add error handling
                    />
                </div>
            )}
        </div>
    )
}
