import React, { useState, useEffect, useRef, useMemo } from "react";
import { Stage, Layer, Image as KonvaImage, Line, Group } from "react-konva";

export default function DynamicChunk({ jsonData }) {
   const [tiles, setTiles] = useState([]);
   const [scale, setScale] = useState(1);
   const [position, setPosition] = useState(null);
   const [sidePanelOpen, setSidePanelOpen] = useState(false);
   const [panelContentUrl, setPanelContentUrl] = useState("");
   const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
   const [lastPosition, setLastPosition] = useState(null);
   const [lastDistance, setLastDistance] = useState(0);
   const [tileSize, setTileSize] = useState(0);
   const [loading, setLoading] = useState(true);
   const stageRef = useRef(null);
   const [stationApi, setStationApi] = useState(new Map());
   const imageName = jsonData.images.find(img => img.id === 1).file_name;
   const [myCart, setMyCart] = useState([]);
   const annotations = jsonData.annotations.filter(anno => anno.image_id === 1);
   const [drag, setDrag] = useState(true);
   const [searchQuery, setSearchQuery] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [searchOpen, setSearchOpen] = useState(false);
   const searchBarRef = useRef(null);
   const [isMouseOverSidebar, setIsMouseOverSidebar] = useState(false);
   const [isPopupVisible, setIsPopupVisible] = useState(false);
   const [selectedPolygonId, setSelectedPolygonId] = useState(null);
   const [activeStationId, setActiveStationId] = useState(false);

    // useEffect(() => {
    //     const fetchMetadata = async () => {
    //         try {
    //             const metadataResponse = await fetch(
    //                 `${process.env.REACT_APP_API_URL}/api/images/${imageName}/metadata`
    //             );
    //             const metadata = await metadataResponse.json();
    //             if (!metadata.imageSize || !metadata.tileSize) {
    //                 throw new Error('Invalid metadata received');
    //             }
    //             setImageSize(metadata.imageSize);
    //             setTileSize(metadata.tileSize);
    //         } catch (error) {
    //             console.error("Error fetching metadata:", error);
    //         }
    //     }; 
    //     fetchMetadata();
    // }, [imageName]);
    // useEffect(() => {
    //     if (imageSize.width === 0 || imageSize.height === 0 || !tileSize) {
    //         return; // Don't proceed if we don't have valid dimensions
    //     }
        
    //     loadImageAndTiles();
    //     getMyCart();
    //     const stationIds = annotations.map(item => item.station_id).join(',');
    //     stationOpacity(stationIds);
    // }, [imageSize, tileSize]);

    useEffect(() => {
        // console.log('Initial useEffect triggered');
        // loadImageAndTiles();
        getMyCart();
        const stationIds = annotations.map(item => item.station_id).join(',');
        stationOpacity(stationIds);
        // setTimeout(() => {
        //     stationOpacity(stationIds);
        // }, 3000);
    }, []);
     
    useEffect(() => {
       
        const initializeAndLoadTiles = async () => {
            try {
                // Only fetch metadata if we don't have it yet
                if (imageSize.width === 0 || imageSize.height === 0) {
                    const metadataResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/images/${imageName}/metadata`
                    );
                    const metadata = await metadataResponse.json();
                    
                    if (!metadata.imageSize || !metadata.tileSize) {
                        throw new Error('Invalid metadata received');
                    }
    
                    // Set initial dimensions and scale
                    const viewportWidth = window.innerWidth;
                    const viewportHeight = window.innerHeight;
                    const scaleX = (viewportWidth * 0.8) / metadata.imageSize.width;
                    const scaleY = (viewportHeight * 0.8) / metadata.imageSize.height;
                    const initialScale = Math.min(scaleX, scaleY);
    
                    setImageSize(metadata.imageSize);
                    setTileSize(metadata.tileSize);
                    setScale(initialScale);
                    setPosition({
                        x: (viewportWidth - (metadata.imageSize.width * initialScale)) / 2,
                        y: (viewportHeight - (metadata.imageSize.height * initialScale)) / 2
                    });
                }
    
                // Only load tiles if we have valid dimensions
                if (imageSize.width > 0 && imageSize.height > 0 && tileSize > 0) {
                    const cols = Math.ceil(imageSize.width / tileSize);
                    const rows = Math.ceil(imageSize.height / tileSize);
    
                    // Create a Set to track which tiles we've already loaded
                    const loadedTileIds = new Set(tiles.map(tile => tile.id));
    
                    // Load only missing tiles
                    const batchSize = 4;
                    for (let y = 0; y < rows; y += batchSize) {
                        for (let x = 0; x < cols; x += batchSize) {
                            const promises = [];
                            for (let dy = 0; dy < batchSize && y + dy < rows; dy++) {
                                for (let dx = 0; dx < batchSize && x + dx < cols; dx++) {
                                    const tileId = `tile-${x + dx}-${y + dy}`;
                                    if (!loadedTileIds.has(tileId)) {
                                        promises.push(loadTile(x + dx, y + dy, tileSize));
                                    }
                                }
                            }
                            if (promises.length > 0) {
                                await Promise.all(promises);
                            }
                        }
                    }
                }
    
                setLoading(false);
    
            } catch (error) {
                console.error("Error in initializeAndLoadTiles:", error);
                setLoading(false);
            }
        };
    
        initializeAndLoadTiles();
    }, [imageName, imageSize.width, imageSize.height, tileSize]);

    // const loadImageAndTiles = async () => { 
    //     try {
    //         console.log('Starting metadata fetch...');
    //         const metadataResponse = await fetch(
    //             `${process.env.REACT_APP_API_URL}/api/images/${imageName}/metadata`
    //         );
    //         const metadata = await metadataResponse.json();
    //         console.log('Received metadata:', metadata);

            
            
    //         if (!metadata.imageSize || !metadata.tileSize) {
    //             throw new Error('Invalid metadata received');
    //         }
            
    //         setImageSize(metadata.imageSize);
    //         setTileSize(metadata.tileSize);

    //         console.log(`Calculated dimensions: ${metadata.imageSize.width}x${metadata.imageSize.height}, tile size: ${metadata.tileSize}`);

    //         const viewportWidth = window.innerWidth;
    //         const viewportHeight = window.innerHeight;
    //         const scaleX = (viewportWidth * 0.8) / metadata.imageSize.width;
    //         const scaleY = (viewportHeight * 0.8) / metadata.imageSize.height;
    //         const initialScale = Math.min(scaleX, scaleY);

    //         setScale(initialScale);
    //         setPosition({
    //             x: (viewportWidth - (metadata.imageSize.width * initialScale)) / 2,
    //             y: (viewportHeight - (metadata.imageSize.height * initialScale)) / 2
    //         });

    //         const cols = Math.ceil(metadata.imageSize.width / metadata.tileSize);
    //         const rows = Math.ceil(metadata.imageSize.height / metadata.tileSize);
    //         console.log('Expected grid:', { cols, rows });
    
    //         console.log(`Loading ${cols}x${rows} tiles...`);
    
    //         // Load tiles in smaller batches
    //         const batchSize = 4;
    //         for (let y = 0; y < rows; y += batchSize) {
    //             for (let x = 0; x < cols; x += batchSize) {
    //                 const promises = [];
    //                 for (let dy = 0; dy < batchSize && y + dy < rows; dy++) {
    //                     for (let dx = 0; dx < batchSize && x + dx < cols; dx++) {
    //                         promises.push(loadTile(x + dx, y + dy, metadata.tileSize));
    //                     }
    //                 }
    //                 console.log(`Loading batch at x:${x}, y:${y}`);
    //                 await Promise.all(promises);
    //             }
    //         }

    //         console.log('Finished loading all tiles');
    //         setLoading(false);
    //     } catch (error) {
    //         console.error("Error in loadImageAndTiles:", error);
    //         setLoading(false);
    //     }
    // };

    // const loadTile = async (x, y, tileSize) => {
    //     return new Promise((resolve, reject) => {
    //         console.log(`Starting to load tile at x:${x}, y:${y}`);
            
    //         const img = new Image();
    //         img.crossOrigin = "anonymous";
            
    //         const width = Math.min(tileSize, imageSize.width - (x * tileSize));
    //         const height = Math.min(tileSize, imageSize.height - (y * tileSize));

    //         if (width <= 0 || height <= 0) {
    //             console.log(`Skipping tile at x:${x}, y:${y} due to invalid dimensions`);
    //             resolve();
    //             return;
    //         }

    //         img.onload = () => {
    //             console.log(`Successfully loaded tile image at x:${x}, y:${y}`);
    //             setTiles(prevTiles => {
    //                 const tileId = `tile-${x}-${y}`;
    //                 console.log(`Adding tile ${tileId} to state`);
                    
    //                 // Log the current tiles state
    //                 console.log('Current tiles count:', prevTiles.length);
                    
    //                 const newTile = {
    //                     id: tileId,
    //                     x,
    //                     y,
    //                     width: img.naturalWidth,
    //                     height: img.naturalHeight,
    //                     image: img
    //                 };
                    
    //                 // Check if tile exists
    //                 const tileExists = prevTiles.some(t => t.id === tileId);
    //                 if (tileExists) {
    //                     console.log(`Tile ${tileId} already exists, skipping`);
    //                     return prevTiles;
    //                 }
                    
    //                 const updatedTiles = [...prevTiles, newTile];
    //                 console.log(`New tiles count:`, updatedTiles.length);
    //                 return updatedTiles;
    //             });
    //             resolve();
    //         };

    //         img.onerror = (error) => {
    //             console.error(`Error loading tile at x:${x}, y:${y}:`, error);
    //             console.error('Image URL:', img.src);
    //             reject(error);
    //         };

    //         const imageUrl = `${process.env.REACT_APP_API_URL}/api/images/${imageName}/tiles/${x}/${y}?quality=original`;
    //         console.log(`Loading tile from URL: ${imageUrl}`);
    //         img.src = imageUrl;
    //     });
    // };
    const loadTile = async (x, y, tileSize) => {
        return new Promise((resolve, reject) => {
            const tileId = `tile-${x}-${y}`;
            
            // Check if tile already exists
            if (tiles.some(t => t.id === tileId)) {
                resolve();
                return;
            }
    
            const img = new Image();
            img.crossOrigin = "anonymous";
            
            const width = Math.min(tileSize, imageSize.width - (x * tileSize));
            const height = Math.min(tileSize, imageSize.height - (y * tileSize));
    
            if (width <= 0 || height <= 0) {
                resolve();
                return;
            }
    
            img.onload = () => {
                setTiles(prevTiles => {
                    if (prevTiles.some(t => t.id === tileId)) {
                        return prevTiles;
                    }
                    return [...prevTiles, {
                        id: tileId,
                        x,
                        y,
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                        image: img
                    }];
                });
                resolve();
            };
    
            img.onerror = reject;
    
            const imageUrl = `${process.env.REACT_APP_API_URL}/api/images/${imageName}/tiles/${x}/${y}?quality=original`;
            img.src = imageUrl;
        });
    };

   useEffect(() => {
       const handleClickOutside = (event) => {
           if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
               setSearchOpen(false);
           }
       };

       document.addEventListener('mousedown', handleClickOutside);

       return () => {
           document.removeEventListener('mousedown', handleClickOutside);
       };
   }, []);

    useEffect(() => {
        // console.log('Tiles updated:', tiles.length);
    }, [tiles]);

    const fetchSearchResults = async (query) => {
        if (!query.trim()) {
            setSearchResults([]);
            return;
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_CARDAPI_URL}/external/search-tags?q=${query}`
            );
            if (response.ok) {
                const data = await response.json(); 
                setSearchResults(data.matchedStations || []);
            }
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };

    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        fetchSearchResults(query);
    };

    const calculatePolygonBounds = (points) => {
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    
        for (let i = 0; i < points.length; i += 2) {
            const x = points[i];
            const y = points[i + 1];
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        }
    
        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    };

    const zoomToAnnotation = (stationId) => {
        const annotation = annotations.find(anno => anno.station_id === stationId);
        if (!annotation) return;
    
        const bounds = calculatePolygonBounds(annotation.segmentation.flat());
        const stage = stageRef.current;
        const stageWidth = stage.width();
        const stageHeight = stage.height();
    
        const padding = 100;
        const scaleX = (stageWidth - padding * 2) / bounds.width;
        const scaleY = (stageHeight - padding * 2) / bounds.height;
        const newScale = Math.min(scaleX, scaleY) * 0.2;
    
        const centerX = bounds.x + bounds.width / 2;
        const centerY = bounds.y + bounds.height / 2;
    
        const newPos = {
            x: stageWidth / 2 - centerX * newScale,
            y: stageHeight / 2 - centerY * newScale
        };
    
        stage.scale({ x: newScale, y: newScale });
        stage.position(newPos);
        stage.batchDraw();
    
        setScale(newScale);
        setPosition(newPos);
    };

    const handleTouchMove = (e) => {
        e.evt.preventDefault();

        const stage = stageRef.current;
        if (!stage) return;

        if (e.evt.touches.length === 2) {
            setDrag(false)
            const touch1 = e.evt.touches[0];
            const touch2 = e.evt.touches[1];

            const centerX = (touch1.clientX + touch2.clientX) / 2;
            const centerY = (touch1.clientY + touch2.clientY) / 2;

            const dist = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );

            if (lastDistance) {
                const scaleBy = dist / lastDistance;
                const oldScale = stage.scaleX();
                const newScale = oldScale * scaleBy;

                const mousePointTo = {
                    x: (centerX - stage.x()) / oldScale,
                    y: (centerY - stage.y()) / oldScale,
                };

                const newPos = {
                    x: centerX - mousePointTo.x * newScale,
                    y: centerY - mousePointTo.y * newScale,
                };

                stage.scale({ x: newScale, y: newScale });
                stage.position(newPos);
                setScale(newScale);
                stage.batchDraw();
            }

            setLastDistance(dist);
        }
        else if (e.evt.touches.length === 1) {
            setDrag(true)
        }
    };

    const handleTouchEnd = () => {
        setLastDistance(null);
        setLastPosition(null);
    };

    const renderTiles = useMemo(() => {
        // console.log('Rendering tiles, count:', tiles.length);
        if (tiles.length === 0) {
            console.log('No tiles available to render');
            return null;
        }
        
        return tiles.map((tile) => {
            // console.log('Rendering tile:', tile.id, 'at position:', tile.x * tileSize, tile.y * tileSize);
            return (
                <KonvaImage
                    key={tile.id}
                    image={tile.image}
                    x={tile.x * tileSize}
                    y={tile.y * tileSize}
                    width={tile.width}
                    height={tile.height}
                    listening={false}
                    imageSmoothingEnabled={false}
                    pixelRatio={2}
                />
            );
        });
    }, [tiles, tileSize]);

   const handleWheel = (e) => {
       e.evt.preventDefault();
       const stage = stageRef.current;
       const oldScale = scale;
       const pointer = stage.getPointerPosition();
   
       const mousePointTo = {
           x: (pointer.x - stage.x()) / oldScale,
           y: (pointer.y - stage.y()) / oldScale
       };
   
       const newScale = e.evt.deltaY > 0 ? oldScale * 0.9 : oldScale * 1.1;
   
       const newPos = {
           x: pointer.x - mousePointTo.x * newScale,
           y: pointer.y - mousePointTo.y * newScale
       };
   
       setScale(newScale);
       setPosition(newPos);
   };

   const categoryColors = {
       1: "#000000",
       2: "#FF0000",
       3: "#0000FF", 
       4: "#5a3c2d",
       5: "#FFFF00",
       6: "#FFA500",
       7: "#008000",
       8: "#800080",
       9: "#808080",
   };

   const handlePolygonClick = (station_id) => {
       setSelectedPolygonId(station_id);
       zoomToAnnotation(station_id);
       setActiveStationId(station_id);
       const url = `${process.env.REACT_APP_CARD_URL}/?frameId=${process.env.REACT_APP_FRAME_ID}&stationId=${station_id}`;
       setSidePanelOpen(true);
       setPanelContentUrl(url);
   };
   
   const addToCart = () => {
       const cartItems = []; 
       if (!localStorage.getItem(`station_cart`)) {
           cartItems.push(activeStationId)
           localStorage.setItem(`station_cart`,JSON.stringify(cartItems)) 
       } else {
           const activeCart = localStorage.getItem(`station_cart`);
           const cItems = JSON.parse(activeCart);
           cItems.push(activeStationId);
           localStorage.setItem(`station_cart`,JSON.stringify(cItems))  
       }
       getMyCart(); 
   }

   const getMyCart = () => {
       const activeCart = localStorage.getItem(`station_cart`);
       const cItems = JSON.parse(activeCart);      
       var uniqueNames = [ ...new Set(cItems) ]; 
       localStorage.setItem(`station_cart`,JSON.stringify(uniqueNames))  
       setMyCart(uniqueNames);
   }

   const deleteCartItem = (station_id) => { 
       const activeCart = localStorage.getItem(`station_cart`);
       if (typeof activeCart!='undefined' && activeCart!=="" && activeCart!=null) {
           const cItems = JSON.parse(activeCart);
           if (typeof cItems!='undefined' && cItems.length>0 ) {
               let arr = cItems.filter(item => item !== station_id); 
               localStorage.setItem(`station_cart`,JSON.stringify(arr)) 
           } 
       }
       getMyCart(); 
   }

   const clearCart = () => {
       localStorage.setItem(`station_cart`,'[]');
       getMyCart(); 
   }

   const copyCart = () =>{
       getMyCart();
       navigator.clipboard.writeText(myCart.join(','))   
       alert('Copy this : '+(myCart.join(',')));
   }

   const handleCheckoutClick = () => {
       setIsPopupVisible(true);
   };

   const confirmClear = () => {
       clearCart();
       setIsPopupVisible(false);
   };

   const cancelCheckout = () => {
       setIsPopupVisible(false);
   };

   function hexToRgb(hex) {
       hex = hex.replace(/^#/, '');
       const bigint = parseInt(hex, 16);
       const r = (bigint >> 16) & 255;
       const g = (bigint >> 8) & 255;
       const b = bigint & 255;
       return [r, g, b];
   }

   const stationOpacity = (stationIds)=> {
       fetch(`${process.env.REACT_APP_CARD_BUILDER_URL}/import/fetch-station-opacity`, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
           },
           body: JSON.stringify({
               station_ids: stationIds,
           }),
       })
       .then(res => res.json())
       .then(data => {
            const opacityMap = new Map();
            const stationdata = [
                {
                    idStations : 52958 ,
                    tessera_opacity : 0
                },
                {
                    idStations : 52959 ,
                    tessera_opacity : 15
                },
                {
                    idStations : 52960 ,
                    tessera_opacity : 30
                },
                {
                    idStations : 52961 ,
                    tessera_opacity :45
                },
                {
                    idStations : 52962 ,
                    tessera_opacity : 60
                },
                {
                    idStations : 52963 ,
                    tessera_opacity : 75
                },
                {
                    idStations : 52964 ,
                    tessera_opacity : 90
                } 
            ]; 
            stationdata.forEach(station=>{
                opacityMap.set(station.idStations, parseFloat(station.tessera_opacity) || 0);
            });
        //    data.data.stations.forEach(station => {
        //        opacityMap.set(station.idStations, parseFloat(station.tessera_opacity) || 0);
        //    });
           setStationApi(opacityMap);
       })
       .catch(error => {
           console.error('Error fetching data:', error);
       });
   }
 

    return (
        <div style={{ width: '100%', height: '100vh', touchAction: 'none', userSelect: 'none', overflow: 'hidden' }}>

            <header className="flex justify-between items-center p-4 bg-[#823a5e] text-white">
                {/* <!-- Start Section --> */}
                <div className="flex-1 text-left text-2xl ms-5">
                    <p>Our Mosaic</p>
                </div>


                {/* <!-- Middle Section --> */}
                <div className="flex-1 text-center">
                    <img
                        draggable="false"
                        src="/logo.png"
                        style={{
                            position: 'static',
                            border: 'none',
                            display: 'inline',
                            cursor: 'inherit',
                            verticalAlign: 'top',
                            visibility: 'inherit',
                            pointerEvents: 'none',
                            userSelect: 'none',
                            width: '99.3377px',
                            height: '50px',
                            margin: '0px 190px 0px 189px'
                        }}
                        alt="..."
                    />
                </div>

                {/* <!-- End Section --> */}
                <div className="flex-1 flex text-right justify-end items-center">
                    {/* <button className="bg-white bg-opacity-10 text-white px-4 py-2 me-5">English</button>
                    <button className="px-4 py-2 me-5">Spanish</button> */}
                    {/* <i className="fa fa-list" style={{ fontSize: "20px" }}></i> */}
                </div>
            </header>


            {/* <!-- Right Sidebar --> */}
            <aside className="fixed top-23 right-0 mb-3 w-64 h-full z-[1050] bg-[#eeeeee] text-black p-4"
                onMouseEnter={() => setIsMouseOverSidebar(true)}
                onMouseLeave={() => setIsMouseOverSidebar(false)}
            >
                <div>
                    <h2 className="text-xl font-semibold mb-3">My Cart</h2>
                </div>
                
                <div className="mt-4 space-y-6 flex flex-col justify-between items-center">
                    {/* Search Bar */}
                    <div
                        className={`absolute flex top-4 right-4 bg-[#823a5e] px-4 py-2 rounded-lg shadow-xl items-center space-x-2`}
                    >
                        <i className="fa fa-search text-white"></i>

                        {/* Input Box */}
                        <input
                            type="text"
                            className="p-3 h-8 w-44 bg-[#823a5e] rounded-lg focus:outline-none text-white placeholder-gray-400"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleInputChange}
                            onFocus={() => setSearchOpen(true)}
                            // onBlur={() => setSearchOpen(false)}
                        />
                    </div>

                    {/* Search Results */}
                    {searchOpen && searchResults.length > 0 && (
                        <div className="bg-[#823a5e] text-left  w-60 mt-2 rounded-lg shadow-lg p-4 max-h-96 overflow-y-auto">
                            {searchResults.map((station_id) => (
                                <div
                                    key={station_id}
                                    onClick={() => handlePolygonClick(station_id)}
                                    className="py-1 px-4 my-1 flex justify-between items-center bg-gray-200 rounded-md cursor-pointer"
                                > 
                                    <p>{station_id}</p> 
                                </div>
                            ))}
                        </div>
                    )}

                    {/* My Cart */}
                    <div
                        className={`bg-[#823a5e] w-60 h-96 rounded-lg py-3 px-3 flex flex-col transition-all duration-300 ease-in-out`}
                    >
                        {/* Cart Title */}
                        <div className="flex justify-between items-center pb-2">
                            <h1 className="text-[#eeeeee] text-lg">My Cart</h1>
                            {
                                myCart.length>0 ?
                                    <button className="bg-[#eeeeee] px-3 py-1 rounded-md" onClick={handleCheckoutClick}>Clear Cart</button>
                                :   ''
                            }
                            {/* Confirmation Popup */}
                            {isPopupVisible && (
                                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-[#eeeeee] p-6 rounded-md shadow-lg text-center">
                                        <h2 className="text-lg font-semibold mb-4">
                                           Clear Cart
                                        </h2>
                                        <p className="mb-6">Are you sure you want to clear all items of your cart?</p>
                                        <div className="flex justify-center gap-4">
                                            <button
                                                className="bg-[#e9ecef] px-4 py-2 rounded-md border border-gray-500"
                                                onClick={cancelCheckout}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="bg-[#823a5e] text-white px-4 py-2 rounded-md"
                                                onClick={confirmClear}
                                            >
                                                Yes, Clear art
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Cart Details (Hidden When Search is Open) */} 
                        <>
                            <div className="flex-1 overflow-y-auto">
                                {myCart.map((item, index) => (
                                    <div
                                        key={index} // Ensure unique key for React list rendering
                                        className="bg-[#eeeeee] px-3 my-2 py-1 rounded-md flex justify-between items-center"
                                    >
                                        <button
                                        onClick={() => handlePolygonClick(item)}
                                        
                                        >{item}</button>
                                        <button className="border border-slate-500 px-2 rounded-md"><i className="fa fa-close" onClick={() => deleteCartItem(item)}></i></button>
                                    </div>
                                ))}
                                {
                                    myCart.length<=0 ?
                                        <div 
                                            className="bg-[#eeeeee] px-3 my-2 py-1 rounded-md flex justify-between items-center"
                                        >
                                            <button>No Items Found</button> 
                                        </div>  
                                    :'' 
                                }
                            </div>

                            {/* Fixed Button */}
                            {
                                myCart.length>0 ?
                                    <div className="mt-2">
                                        <button className="bg-[#fff] rounded-md text-sm px-5 py-1 w-full" onClick={() => copyCart()}>Copy Cart</button>
                                        <div className="text-white font-bold text-sm mt-2" >
                                            <a target='_blank'  rel="noreferrer" href={process.env.REACT_APP_DONATION_LINK} >Donate Now</a>
                                        </div>
                                    </div>
                                :   ''
                            }
                            
                        </>
                        
                    </div>
                </div>
            </aside>


            <div className="z-0">
                {
                    position && (
                        <Stage
                            ref={stageRef}
                            width={window.innerWidth}
                            height={window.innerHeight}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                            onWheel={handleWheel}
                            scale={{ x: scale, y: scale }}
                            position={position}
                            draggable={drag && !isMouseOverSidebar}
                        >
                            <Layer>
                                <Group>
                                    {renderTiles}
                                </Group>
                                {loading === false && annotations.map((annotation, index) => {
                                    const stationValue = stationApi.get(annotation.station_id);
                                    const calculatedOpacity = stationValue > 0 ? stationValue / 100 : 0;
                                    // console.log('OPACITY',stationValue,calculatedOpacity)
                                    const fillColor = stationValue
                                        ? `rgba(${hexToRgb(categoryColors[annotation.category_id]).join(',')}, ${calculatedOpacity})`
                                        : 'transparent'; 
        
                                    
                                     // Set stroke width based on whether this polygon is selected
                                    const isSelected = selectedPolygonId === annotation.station_id;
                                    const baseStrokeWidth = 1 / scale;
                                    const strokeWidth = isSelected ? baseStrokeWidth * 5 : baseStrokeWidth;
                                    

                                    return (
                                        <Line
                                            key={index} 
                                            points={annotation.segmentation.flat()}
                                            closed
                                            fill={fillColor}
                                            stroke={categoryColors[annotation.category_id]}
                                            strokeOpacity={4}
                                            strokeWidth={strokeWidth}
                                            onClick={() => handlePolygonClick(annotation.station_id)}
                                            onMouseEnter={e => {
                                                e.target.strokeWidth(strokeWidth);
                                                e.target.getLayer().batchDraw();
                                            }}
                                            onMouseLeave={e => {
                                                e.target.strokeWidth(strokeWidth);
                                                e.target.getLayer().batchDraw();
                                            }}
                                        />
                                    );
                                })}
                            </Layer>
                        </Stage>
                    )
                }

            </div>
            {/* Search Button */}
            {/* {
                searchOpen === false && (
                    <button
                        onClick={toggleSearchBar}
                        className="absolute top-4 right-4 py-3 px-4 bg-[#eeeeee] text-[#823a5e] rounded-full shadow-lg hover:bg-[#823a5e] transition-all duration-300"
                    >
                        <i className="fa fa-search"></i>
                    </button>
                )
            } */}


            {/* Search Bar */}


            {sidePanelOpen && (
                <div className="side-panel fixed bottom-0 left-0 h-[80%] bg-white shadow-md z-[1000] md:w-[20%] w-[30%]">
                    <header
                        className="bg-[#eeeeee] flex justify-between items-center px-4 w-full h-12 z-[1050] absolute top-0"
                    >
                        <div>
                            <button className="px-4 py-2 bg-[#823a5e] text-white rounded"
                            onClick={addToCart}
                            >Add to Cart</button>
                        </div>
                        <div>
                            <button
                                onClick={() => setSidePanelOpen(false)}
                                className="px-4 py-2 bg-[#823a5e] text-white font-bold rounded"
                            >
                                X
                            </button>
                        </div>
                    </header>

                    {/* Add this to debug */}
                    <div style={{ position: 'absolute', top: '20px', fontSize: '10px' }}>
                        Loading URL: {panelContentUrl}
                    </div>
                    <iframe
                        allowFullScreen={true}
                        allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; autoplay; camera; microphone; display-capture; xr-spatial-tracking"
                        src={panelContentUrl}
                        style={{ position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%" }}
                        title="side panel"
                        onError={(e) => console.log('iframe error:', e)} // Add error handling
                    />
                </div>
            )}
        </div>
    );
}