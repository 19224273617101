import React, { useState, useEffect, useRef, useMemo } from "react";
import { Stage, Layer, Image as KonvaImage, Line, Group } from "react-konva";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'; // Import the styles

// Target tile size in bytes (150KB)
const TARGET_TILE_SIZE = 150 * 1024;

export default function ImageDisplay({ jsonData }) {
  const [tiles, setTiles] = useState(new Map());
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [panelContentUrl, setPanelContentUrl] = useState("");
  const [tileSize, setTileSize] = useState(null);
  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [drag, setDrag] = useState(true);
  const stageRef = useRef(null);
  const containerRef = useRef(null);
  const [lastDistance, setLastDistance] = useState(null);
  const [lastPosition, setLastPosition] = useState(null);
  const [stationApi, setStationApi] = useState(new Map());
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const imageData = jsonData.images.find(img => img.id === 1);
  const annotations = jsonData.annotations.filter(anno => anno.image_id === 1);
  const baseImageUrl = `/${imageData.file_name}`;

  // Calculate initial scale and position for 80% size
  const calculateInitialTransform = (imgWidth, imgHeight) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate scale to fit 80% of viewport
    const scaleX = (viewportWidth * 0.8) / imgWidth;
    const scaleY = (viewportHeight * 0.8) / imgHeight;
    const newScale = Math.min(scaleX, scaleY);

    // Calculate position to center
    const x = (viewportWidth - (imgWidth * newScale)) / 2;
    const y = (viewportHeight - (imgHeight * newScale)) / 2;

    return { scale: newScale, position: { x, y } };
  };

  // Initialize image and setup
  const initializeImage = () => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = baseImageUrl;

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // Calculate optimal tile size
        const totalPixels = width * height;
        const BYTES_PER_PIXEL = 4;
        const targetPixelsPerTile = TARGET_TILE_SIZE / BYTES_PER_PIXEL;
        let tileDimension;

        if (totalPixels > 100000000) {
          tileDimension = 1024;
        } else if (totalPixels > 25000000) {
          tileDimension = 512;
        } else {
          tileDimension = 256;
        }

        // Calculate initial transform for 80% size
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const scaleX = (viewportWidth * 0.8) / width;
        const scaleY = (viewportHeight * 0.8) / height;
        const initialScale = Math.min(scaleX, scaleY);
        const initialPosition = {
          x: (viewportWidth - (width * initialScale)) / 2,
          y: (viewportHeight - (height * initialScale)) / 2
        };

        resolve({
          width,
          height,
          tileSize: tileDimension,
          scale: initialScale,
          position: initialPosition
        });
      };
    });
  };

  // Fast tile loading from center
  const loadTilesFromCenter = async () => {
    if (!tileSize || !imageSize.width || !imageSize.height) return;

    const img = new Image();
    img.src = baseImageUrl;

    await new Promise(resolve => {
      img.onload = () => {
        const numTilesX = Math.ceil(imageSize.width / tileSize);
        const numTilesY = Math.ceil(imageSize.height / tileSize);
        const centerTileX = Math.floor(numTilesX / 2);
        const centerTileY = Math.floor(numTilesY / 2);
        const newTiles = new Map();

        // Create a sorted array of tiles based on distance from center
        const tileQueue = [];
        for (let y = 0; y < numTilesY; y++) {
          for (let x = 0; x < numTilesX; x++) {
            const distance = Math.sqrt(
              Math.pow(x - centerTileX, 2) +
              Math.pow(y - centerTileY, 2)
            );
            tileQueue.push({ x, y, distance });
          }
        }

        // Sort tiles by distance from center
        tileQueue.sort((a, b) => a.distance - b.distance);

        // Create all tiles at once
        for (const tile of tileQueue) {
          const tileKey = `${tile.x}-${tile.y}`;
          const tileData = createTileCanvas(img, tile.x, tile.y);
          if (tileData) {
            newTiles.set(tileKey, tileData);
          }
        }

        // Set all tiles at once
        setTiles(newTiles);
        resolve();
      };
    });
  };

  // Initialize component
  useEffect(() => {
    const setup = async () => {
      try {
        const initData = await initializeImage();
        setImageSize({ width: initData.width, height: initData.height });
        setTileSize(initData.tileSize);
        setScale(initData.scale);
        setPosition(initData.position);
        setImageLoaded(true);
        setInitialSetupDone(true);
      } catch (error) {
        console.error('Error initializing image:', error);
      }
    };
    setup();
  }, [baseImageUrl]);

  // Load tiles after initialization
  useEffect(() => {
    if (imageLoaded && initialSetupDone && tileSize) {
      loadTilesFromCenter();
    }
  }, [imageLoaded, initialSetupDone, tileSize]);

  // create the tiles canvas
  const createTileCanvas = (image, tileX, tileY) => {
    if (!tileSize) return null;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const sourceX = tileX * tileSize;
    const sourceY = tileY * tileSize;
    const availableWidth = Math.min(tileSize, imageSize.width - sourceX);
    const availableHeight = Math.min(tileSize, imageSize.height - sourceY);

    if (availableWidth <= 0 || availableHeight <= 0) return null;

    canvas.width = availableWidth;
    canvas.height = availableHeight;

    ctx.drawImage(
      image,
      sourceX, sourceY,
      availableWidth, availableHeight,
      0, 0,
      availableWidth, availableHeight
    );

    const tileImage = new Image();
    tileImage.src = canvas.toDataURL('image/png');

    return {
      image: tileImage,
      width: availableWidth,
      height: availableHeight
    };
  };

  const renderTiles = useMemo(() => {
    if (!tileSize) return [];
    return Array.from(tiles.entries()).map(([key, tileData]) => {
      const [tileX, tileY] = key.split('-').map(Number);
      return (
        <KonvaImage
          key={key}
          image={tileData.image}
          x={tileX * tileSize}
          y={tileY * tileSize}
          width={tileData.width}
          height={tileData.height}
        />
      );
    });
  }, [tiles, tileSize]);

  // Touch interaction handlers
  const [touchState, setTouchState] = useState({
    lastCenter: null,
    lastDistance: 0,
    isDragging: false,
    startPosition: null,
    isZooming: false,
    initialScale: 1
  });

  const getDistance = (touch1, touch2) => {
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const getCenter = (touch1, touch2) => ({
    x: (touch1.clientX + touch2.clientX) / 2,
    y: (touch1.clientY + touch2.clientY) / 2
  });

  const handleTouchMove = (e) => {
    e.evt.preventDefault();

    const stage = stageRef.current;
    if (!stage) return;

    if (e.evt.touches.length === 2) {
      setDrag(false)
      // Zooming with two-finger gesture
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      // Calculate the center point of the two touches
      const centerX = (touch1.clientX + touch2.clientX) / 2;
      const centerY = (touch1.clientY + touch2.clientY) / 2;

      // Calculate the distance between touch points
      const dist = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY
      );

      if (lastDistance) {
        // Calculate scale factor
        const scaleBy = dist / lastDistance;

        // Retrieve the current scale
        const oldScale = stage.scaleX();
        const newScale = oldScale * scaleBy;

        // Calculate the pointer position relative to the stage
        const mousePointTo = {
          x: (centerX - stage.x()) / oldScale,
          y: (centerY - stage.y()) / oldScale,
        };

        // Calculate new position to zoom from the center
        const newPos = {
          x: centerX - mousePointTo.x * newScale,
          y: centerY - mousePointTo.y * newScale,
        };

        // Apply transformation
        stage.scale({ x: newScale, y: newScale });
        stage.position(newPos);

        // Update scale in state for consistency
        setScale(newScale);

        // Redraw the stage
        stage.batchDraw();
      }

      // Update last distance for the next touch move
      setLastDistance(dist);
    } 
    else if (e.evt.touches.length === 1) {
      setDrag(true)
    }
    // else if (e.evt.touches.length === 1) {
    //   // Handling single touch (dragging) when the stage is draggable
    //   const touch = e.evt.touches[0];
    //   setDrag(true)

    //   if (stage.isDragging()) {
    //     // If stage is already being dragged, prevent zooming
    //     return;
    //   }

    //   if (stage.attrs.draggable === true) {
    //     // Only allow dragging if the stage is draggable
    //     if (!lastPosition) {
    //       setLastPosition({
    //         x: touch.clientX,
    //         y: touch.clientY,
    //       });
    //       return;
    //     }

    //     // Calculate drag delta
    //     const deltaX = touch.clientX - lastPosition.x;
    //     const deltaY = touch.clientY - lastPosition.y;

    //     // Get current stage position
    //     const currentPos = stage.position();

    //     // Apply new position
    //     stage.position({
    //       x: currentPos.x + deltaX,
    //       y: currentPos.y + deltaY,
    //     });

    //     // Update last position
    //     setLastPosition({
    //       x: touch.clientX,
    //       y: touch.clientY,
    //     });

    //     // Redraw the stage
    //     stage.batchDraw();
    //   }
    // }
  };

  const handleTouchEnd = () => {
    // Reset tracking variables
    setLastDistance(null);
    setLastPosition(null);
  };


  const handleWheel = (e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const oldScale = scale;
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * 0.9 : oldScale * 1.1;
    setScale(newScale);

    setPosition({
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale
    });
  };


  const categoryColors = {
    1: "#000000", // Black Stone
    2: "#FF0000", // Red Stone
    3: "#0000FF", // Blue Stone
    4: "#FFFFFF", // White Stone
    5: "#FFFF00", // Jesus
    6: "#FFA500", // Anchor
    7: "#008000", // Boat
    8: "#800080", // Menora
    9: "#808080", // Gray
  };


  const handlePolygonClick = (annotation) => {
    const url = `https://cardviewer.beta.experien.city/?parentURL=https%3A%2F%2Fbeta.experien.city&frameId=402&stationId=${annotation.station_id}`;
    console.log('URL being set:', url); // Add this log
    setSidePanelOpen(true);
    setPanelContentUrl(url);
  };


  function hexToRgb(hex) {
    // Remove '#' if present
    hex = hex.replace(/^#/, '');

    // Parse the color
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }


  useEffect(() => {
    fetch('https://cardbuilderapi.uat.experien.city/import/station-opacity?station_ids=23645,23646,23647,23648,23649,23650,23651,23652,23653,23654,23655,23656,23657,23658,23659,23660,23661,23662,23663,23664,23665,23666,23667,23668,23669,23670,23671,23672,23673,23674,23675,23676,23677,23678,23679,23680,23681,23682,23683,23684,23685,23686,23687,21908,21909,21910,21911,21912,21913,21914,21915,21916,21917,21918,21919,21920,21921,21922,21923,21924,21925,21926,21927,21928,21929,21930,21931,21932,21933,21934,21935,21936,21937,21938,21939,21940,21941,21942,7468,7469,7470,7471,7472,7474,7475,7476,7477,7478,7521,7569,9547,9578,9743,9745,10154,10247,10276,11537,11739,11789,11807,11883,12236,12743,13259,13882,15163,15169,22585,30591,34233,34234,27245,32742,32743,32744,32745,33134,33135,33137,33139,33140,33141,33142,33143,33144,33145,33146,33147,33148,33149,33150,33151,33152,33153,20693,20694,20695,20696,20697,20698,20699,20700,20701,20702,20703,20704,20705,20706,20707,20708,20709,20710,20711,20712,20713,20714,20715,20716,20717,20718,20719,20720,20721,20722,20723,20724,20725,20726,20727,20728,20729,20730,20731,20732,20733,20734,20735,20736,20737,20738,20739,20740,20741,20742,20743,20744,20745,20746,20747,20748,20749,20750,20751,20752,20753,20754,20755,20756,20757,20758,20759,20760,20761,20762,20763,20764,20765,20766,20767,20768,20769,20770,20771,20772,20871,20872,20873,20874,20875,20876,20877,20878,20879,20880,20881,20882,20883,20884,20885,20886,20887,20888,20889,20890,20891,21268,21269,21270,21271,21272,21273,21274,21275,21276,21277,21278,21279,21280,21281,21282,21283,21284,21285,21286,21287,21288,21289,21290,21291,21292,21293,21294,21295,21296,21297,21298,21299,21300,21301,21302,21303,21304,21305,21306,21307,21308,21309,21310')
      .then(res => res.json())
      .then(data => {
        const opacityMap = new Map();
        data.data.stations.forEach(station => {
          opacityMap.set(station.idStations, parseFloat(station.tessera_opacity) || 0);  // Default to 1 if no opacity
        });
        console.log(opacityMap);
        setStationApi(opacityMap); // Set the opacity map with station id and opacity values

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Don't render until initial position is set
  if (!position) {
    return <div>Loading...</div>;
  }

  return (
    <div ref={containerRef} style={{
      width: '100%', height: '100vh', touchAction: 'none',
      userSelect: 'none', overflow: 'hidden'
    }}>
        <Stage 
          ref={stageRef}
          width={window.innerWidth}
          height={window.innerHeight}
          onWheel={handleWheel}
          // onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          scale={{ x: scale, y: scale }}
          position={position}
          draggable = {drag}
        >
          <Layer>
            <Group>
              {renderTiles}
            </Group>
            {annotations.map((annotation, index) => {
              const stationValue = stationApi.get(annotation.station_id);
              const calculatedOpacity = stationValue > 0 ? stationValue / 100 : 0;
              const fillColor = stationValue
                ? `rgba(${hexToRgb(categoryColors[annotation.category_id]).join(',')}, ${calculatedOpacity})`
                : 'transparent';
              return (
                <Line
                  key={index}
                  points={annotation.segmentation.flat()}
                  closed
                  fill={fillColor}
                  stroke={categoryColors[annotation.category_id]}
                  strokeOpacity={1}
                  strokeWidth={2 / scale}
                  onClick={() => handlePolygonClick(annotation)}
                  onMouseEnter={e => {
                    e.target.strokeWidth(2 / scale);
                    e.target.getLayer().batchDraw();
                  }}
                  onMouseLeave={e => {
                    e.target.strokeWidth(2 / scale);
                    e.target.getLayer().batchDraw();
                  }}
                />
              );
            })}
          </Layer>
        </Stage>
      
      {sidePanelOpen && (
        <div className="side-panel fixed bottom-0 left-0 h-[80%] bg-white shadow-md z-[1000] md:w-[20%] w-[30%]">
          <button onClick={() => setSidePanelOpen(false)} style={{ position: 'absolute', top: '0px', right: '0px', border: "none", color: "#fff", backgroundColor: "#823a5e", zIndex: "1050", fontWeight: "bold", paddingInline: "10px", paddingBlock: "5px" }}>
            X
          </button>
          {/* Add this to debug */}
          <div style={{ position: 'absolute', top: '20px', fontSize: '10px' }}>
            Loading URL: {panelContentUrl}
          </div>
          <iframe
            allowFullScreen={true}
            allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; autoplay; camera; microphone; display-capture; xr-spatial-tracking"
            src={panelContentUrl}
            style={{ position: "absolute", left: "0px", top: "0px", width: "100%", height: "100%" }}
            title="side panel"
            onError={(e) => console.log('iframe error:', e)} // Add error handling
          />
        </div>
      )}
    </div>
  );
}