import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Home from './Home';
import jsonData from './new.json'
// import jsonData2 from './new.json';
import ImageDisplay from './ImageDisplay';
// import ImageDisplayNew from './ImageDisplayNew';
import './index.css'
import DynamicChunk from './DynamicChunk';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/home' element={<Home jsonData={jsonData}></Home>} />
          <Route path='/' element={<DynamicChunk jsonData={jsonData}></DynamicChunk>} />
          <Route path='/dynamic' element={<ImageDisplay jsonData={jsonData}></ImageDisplay>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
